/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment } from 'react';
import classname from 'classname';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { Enum } from '@pageproof/sdk';
import FromNow from '../Date/FromNow';
import Verbose from '../Date/Verbose';
import { Avatar } from '../Avatar';
import Avatars from '../Avatars';
import css from './AuditSummary.scss';
import Hover from '../Hover';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import TimeRange from '../Date/TimeRange';
import Reveal from '../Reveal';
import ThumbsUp from '../Decisions/Icons/ThumbsUp';
import ThumbsUpOrange from '../Decisions/Icons/ThumbsUpOrange';
import ThumbsDown from '../Decisions/Icons/ThumbsDown';
import Dropdown, {
  Option as DropdownOption,
  OptionLabel as DropdownOptionLabel,
} from '../Dropdown';
import RoleDot from '../RoleDot';

// Consecutive events of this type will not get grouped together by 'groupSimilarEntries'
const EXPANDED_ENTRY_TYPES = [
  'user.editor_assigned',
  'proof.step_visible',
  'user.comments_imported',
];

/* eslint-disable */
function getEntryChildren(entry, multiple, proofType) {
  const isProofType = entry.type.startsWith('proof.');
  if (isProofType && proofType !== 'proof') {
    entry.type = entry.type.replace("proof", proofType);
  }
  switch (entry.type) {
    case 'proof.new_version_uploaded':
    case 'brief.new_version_uploaded': {
      return (
        <Translation
          value={`audit-summary.entry.${proofType}.new_version_uploaded`}
          params={{
            version: (
              <Fragment>
                <Tooltip
                  up
                  center
                  title={entry.proof.name}
                  delay={700}
                  disabled={!entry.proof.name}
                >
                  <span style={{textDecoration: 'underline dotted', cursor: 'help'}}>
                    <Translation value="audit-summary.version" params={{number: entry.proof.version}} />
                  </span>
                </Tooltip>
              </Fragment>
            )
          }}
        />
      );
    }
    case 'user.proof_created': {
      return <Translation value={`audit-summary.entry.user.${proofType}_created`} />;
    }
    case 'proof.started_from_step': {
      return (
        <Tooltip
          title={entry.step.name}
          maxWidth={200}
          up
          center
          offset={10}
          disabled={!entry.step.name}
        >
          <div>
            {entry.step.position === 1000 
              ? <Translation value="audit-summary.entry.proof.started_from_step.final" />
              : <Translation value="audit-summary.entry.proof.started_from_step" params={{position: entry.step.position}} />
            }
          </div>
        </Tooltip>
      );
    }
    case 'proof.step_visible': {
      if (entry.step.position === 1000) {
        return <Translation value="audit-summary.entry.proof.step_visible.final" />;
      }

      if (entry.step.name) {
        return <Translation value="audit-summary.entry.proof.step_visible.named" params={{ step: entry.step.number, name: entry.step.name }} />;
      }

      return <Translation value="audit-summary.entry.proof.step_visible" params={{ step: entry.step.number }} />;
    }
    case 'user.editor_assigned': {
      return (
        <Translation
          value={`audit-summary.entry.user.editor_assigned`}
          params={{ editorEmail: entry.editor.email }}
        />
      );
    }
    case 'user.finished': {
      return (
        <Translation
          value={`audit-summary.entry.user.finished.button.${entry.button}${multiple ? '.multiple' : ''}`}
        />
      );
    }
    case 'user.user_viewed': {
      let translationKey = '';

      if (multiple) {
        translationKey = proofType === 'brief' ? 'audit-summary.entry.user.viewed_brief.multiple.count' : 'audit-summary.entry.user.viewed_proof.multiple.count';
      } else {
        translationKey = proofType === 'brief' ? 'audit-summary.entry.user.viewed_brief' : 'audit-summary.entry.user.viewed_proof';
      }

      return (
        <Translation
          value={translationKey}
          params={{ count: entry.groupedEventsCount }}
        />
      );
    }
    case 'user.comments_imported': {
      let translationStringKey;
      // replyCount could be undefined
      if (entry.commentCount === 0 && !entry.replyCount) {
        translationStringKey = 'audit-summary.entry.user.comments_imported.none';
      } else if (entry.commentCount === 0) {
        translationStringKey = entry.replyCount > 1 ? 'audit-summary.entry.user.comments_imported.replies' : 'audit-summary.entry.user.comments_imported.reply';
      } else if (!entry.replyCount) {
        translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments' : 'audit-summary.entry.user.comments_imported.comment';
      } else if (entry.replyCount === 1) {
        translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments-with-reply' : 'audit-summary.entry.user.comments_imported.comment-with-reply';
      } else if (entry.replyCount > 1) {
        translationStringKey = entry.commentCount > 1 ? 'audit-summary.entry.user.comments_imported.comments-with-replies' : 'audit-summary.entry.user.comments_imported.comment-with-replies';
      }

      return (
        <Translation
          value={translationStringKey}
          params={{
            commentCount: entry.commentCount,
            replyCount: entry.replyCount,
            sourceType: entry.sourceType,
            sourceName: entry.sourceName,
          }}
        />
      );
    }
    case 'special.missing': {
      return (
        <Tooltip
          title={
            <Translation
              value={`audit-summary.entry.${entry.type}.tooltip`}
            />
          }
          maxWidth={200}
          up
          center
          offset={10}
        >
          <div>
            <InlineSVG
              className={css.MissingIcon}
              src="img/content/proof/icons/metadata/more.svg"
            />
          </div>
        </Tooltip>
      );
    }
    default: {
      const isUserType = entry.type.startsWith('user.');
      return (
        <Translation
          value={`audit-summary.entry.${entry.type}${(isUserType && multiple)
            ? '.multiple'
            : ''}`}
        />
      );
    }
  }
}
/* eslint-enable */

function toDay(date) {
  const day = new Date(date);
  // debugger; // eslint-disable-line
  day.setHours(0);
  day.setMinutes(0);
  day.setSeconds(0);
  day.setMilliseconds(0);
  return day.toISOString();
}

function groupSimilarEntries(entries, compact) {
  const grouped = [];
  let previousEntryHash = null;
  let previousEntryDay = 0;
  let previousEntry = null;
  for (let i = 0; i < entries.length; i += 1) {
    const entry = Object.assign({ groupedEventsCount: 1 }, entries[i]);
    const day = toDay(entry.date);
    const sameDay = day === previousEntryDay;
    entry.sameDay = sameDay;
    let entryHash = day + entry.type;
    if (['user.finished', 'user.decision_changed'].includes(entry.type)) {
      entryHash += entry.role + entry.button + entry.decision; // group users by their role on the proof, and their decision
    }
    if (compact && (entryHash === previousEntryHash) && !EXPANDED_ENTRY_TYPES.includes(entry.type)) {
      const isExistingUser = (previousEntry.user && previousEntry.user.id === entry.user.id) ||
        (previousEntry.users && previousEntry.users.some(({ id }) => id === entry.user.id));

      if (!isExistingUser) {
        if (previousEntry.user) {
          previousEntry.users = [{
            ...previousEntry.user,
            date: previousEntry.date,
          }];
          delete previousEntry.user;
        }

        if (entry.user) {
          previousEntry.users.push({
            ...entry.user,
            date: entry.date,
          });
        }
      }

      previousEntry.groupedEventsCount += 1;
    } else {
      previousEntry = entry;
      grouped.push(entry);
    }
    previousEntryHash = entryHash;
    previousEntryDay = day;
  }
  return grouped;
}

function showRoleDot(role) {
  return (role === 'gatekeeper' || role === 'mandatory' || role === 'approver');
}

function AuditSummary({
  summary,
  compact,
  proofType,
  isEnterpriseAccount,
  selectedEvent,
  onSelectEvent,
  hasDecisionsEnabled,
  viewType,
  setViewType,
  hasChecklistEvents,
}) {
  const entries = groupSimilarEntries(summary, compact);
  let firstDate;
  let lastDate;
  if (entries.length) {
    firstDate = entries[0].date;
    lastDate = entries[entries.length - 1].date;
  }
  return (
    <div className={css.AuditSummary}>
      <div className={css.AuditSummaryDropdownWrapper}>
        <Dropdown selected={viewType}>
          <DropdownOption
            value="lessDetail"
            label={
              <DropdownOptionLabel
                title={<Translation value="audit-summary.dropdown.less-detail" />}
              />
            }
            onClick={() => setViewType('lessDetail')}
          />
          <DropdownOption
            value="fullDetail"
            label={
              <DropdownOptionLabel
                title={<Translation value="audit-summary.dropdown.more-detail" />}
                aside={!isEnterpriseAccount && (
                  <Translation value="label.available-on-enterprise" />
                )}
              />
            }
            onClick={() => setViewType('fullDetail')}
          />
          {hasChecklistEvents &&
            <DropdownOption
              value="checklist"
              label={
                <DropdownOptionLabel
                  title={<Translation value="audit-summary.dropdown.checklist-view" />}
                  aside={!isEnterpriseAccount && (
                    <Translation value="label.available-on-enterprise" />
                  )}
                />
              }
              onClick={() => setViewType('checklist')}
            />
          }
        </Dropdown>
      </div>
      <div>
        {entries.map((entry, index) => {
          const left = entry.type.startsWith('proof.');
          const center = entry.type.startsWith('special.');
          const right = entry.type.startsWith('user.');
          return (
            <div
              key={index}
              className={classname(
                css.AuditSummary__entry,
                {
                  [css['AuditSummary__entry--left']]: left,
                  [css['AuditSummary__entry--center']]: center,
                  [css['AuditSummary__entry--right']]: right,
                }
              )}
            >
              <div
                className={classname(css.AuditSummary__entry__line, {
                  [css['AuditSummary__entry__line--same-day']]: (entry.sameDay && index !== selectedEvent),
                })}
              />
              <div style={{ width: center ? '' : '100%' }}>
                {!center &&
                  <Reveal
                    visible={!entry.sameDay || index === selectedEvent}
                    align="bottom"
                    duration={500}
                  >
                    {() => (
                      <div className={css.AuditSummary__entry__date}>
                        <Hover>
                          {({ Target, isActive }) => (
                            <Target>
                              <span>
                                {isActive
                                  ? <Verbose date={new Date(entry.date)} />
                                  : <FromNow date={new Date(entry.date)} />
                                }
                              </span>
                            </Target>
                          )}
                        </Hover>
                      </div>
                    )}
                  </Reveal>
                }
                <div
                  className={css.AuditSummary__entry__tooltip}
                  onClick={() => onSelectEvent(selectedEvent === index ? -1 : index)}
                >
                  {(right && (entry.user || entry.users)) &&
                    <div className={css.AuditSummary__entry__tooltip__user}>
                      {entry.users
                        ? (
                          <Fragment>
                            <div style={{ fontSize: 0, marginRight: 6 }}>
                              <Avatars
                                avatars={entry.users.slice(0, 6).map(user => ({
                                  url: user.avatar,
                                  tooltip: (
                                    <div>
                                      {user.email}
                                      <br />
                                      <small>
                                        <Verbose date={new Date(user.date)} />
                                      </small>
                                    </div>
                                  ),
                                }))}
                                size={20}
                                overlap={6}
                                color="#fff"
                              />
                            </div>
                            {showRoleDot(entry.role) &&
                              <RoleDot
                                userRole={entry.role}
                              />
                            }
                          </Fragment>
                        )
                        : (
                          <Fragment>
                            <div style={{ fontSize: 0, padding: 3 }}>
                              <Tooltip
                                title={entry.user.email}
                                up
                                center
                                arrow
                                delay={0}
                                offset={13}
                              >
                                <div>
                                  <Avatar
                                    url={entry.user.avatar}
                                    size={20}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                            <span className={css.AuditSummary__entry__tooltip__user__email}>
                              {entry.user.email}
                            </span>
                            {showRoleDot(entry.role) &&
                              <RoleDot
                                userRole={entry.role}
                              />
                            }
                          </Fragment>
                        )
                      }
                    </div>
                  }
                  <div className={css.AuditSummary__entry__tooltip__description}>
                    {getEntryChildren(entry, entry.groupedEventsCount > 1, proofType)}
                  </div>
                </div>
                {right && entry.decision && hasDecisionsEnabled &&
                  <div
                    className={classname(css.AuditSummary__entry__tooltip__decision, {
                      [css['AuditSummary__entry__tooltip__decision--same-day']]: (entry.sameDay && index !== selectedEvent),
                    })}
                  >
                    {entry.decision === Enum.Decision.APPROVED &&
                      <ThumbsUp
                        activeColor="#138b3b"
                        size={16}
                        active
                        readOnly
                      />
                    }
                    {entry.decision === Enum.Decision.APPROVED_WITH_CHANGES &&
                      <ThumbsUpOrange
                        activeColor="#fe8125"
                        size={16}
                        active
                        readOnly
                      />
                    }
                    {entry.decision === Enum.Decision.SEND_CHANGES &&
                      <ThumbsDown
                        activeColor="#e51c23"
                        size={16}
                        active
                        readOnly
                      />
                    }
                  </div>
                }
              </div>
            </div>
          );
        })}
      </div>
      {(firstDate && lastDate && firstDate !== lastDate) && (
        <Hover>
          {({ Target, isActive }) => (
            <div className={css.AuditSummary__span}>
              <Target>
                <span>
                  {isActive
                    ? (
                      <span>
                        <Verbose date={new Date(firstDate)} />
                        {' - '}
                        <Verbose date={new Date(lastDate)} />
                      </span>
                    )
                    : (
                      <TimeRange
                        fromDate={new Date(firstDate)}
                        toDate={new Date(lastDate)}
                      />
                    )
                  }
                </span>
              </Target>
            </div>
          )}
        </Hover>
      )}
    </div>
  );
}

AuditSummary.defaultProps = {
  proofType: 'proof',
  hasDecisionsEnabled: false,
};

export default AuditSummary;
