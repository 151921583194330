/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { sdk } from '../../util/sdk';
import { DASHBOARD_LIMIT } from '../../util/constants';
import Translation from '../../components/Text/Translation';

class DashboardTab {
  isPending = false;

  proofs = [];

  proofIds = [];

  proofCount = 0;

  search = '';

  page = 0;

  limit = DASHBOARD_LIMIT;

  allowScheduledRefresh = true;

  constructor(id, label, getTitle, endpoint, isStatic, isTeam, pageNumber, allowScheduledRefresh = true) {
    this.id = id;
    this.label = label;
    this.getTitle = getTitle;
    this.endpoint = endpoint;
    this.isStatic = isStatic || false;
    this.isTeam = isTeam || false;
    this.page = pageNumber || 0;
    this.allowScheduledRefresh = allowScheduledRefresh;
  }

  changePage = (page) => {
    this.page = page || 1;
  }

  updateSearchData = (search, filters) => {
    this.search = search;
    this.filters = filters;
  }

  togglePending = (bool) => {
    this.isPending = bool;
  }

  request = () => {
    let data = null;
    let firstArg = null;
    if (this.endpoint === 'group') {
      data = this.groupId;
    }
    if (this.page) {
      data = this.page;
      firstArg = this.page;
    }
    if (this.endpoint === 'search' || this.endpoint === 'teamSearch') {
      data = {
        status: this.filters,
        everything: 0,
        page: this.page,
      };
      firstArg = this.search;
    }

    if (this.endpoint === 'group') {
      return sdk.proofs.groups.load(data)
        .then(
          response => ({
            proofs: response.proofs,
            count: response.proofs.length,
            addedData: {
              canAddProofs: response.canAddProofs,
              isEmpty: response.isEmpty,
              groupName: response.name,
            },
          }),
          error => ({
            error: (error &&
            error.response &&
            error.response.data &&
            error.response.data.ResponseStatus),
          })
        );
    } else if (this.isTeam) {
      return sdk.admin()
        .then(admin => admin.team.dashboard[this.endpoint](firstArg, data))
        .then(proofData => ({
          proofs: proofData.proofs,
          count: proofData.total,
        }));
    } else {
      return sdk.dashboard[this.endpoint](firstArg, data)
        .then(proofData => ({
          proofs: proofData,
          count: proofData.length,
        }));
    }
  }

  static inbox = () => new DashboardTab('inbox', <Translation value="dashboard.tab.Inbox" />, () => Translation.text('dashboard.tab.Inbox'), 'inbox', true);

  static sent = () => new DashboardTab('sent', <Translation value="dashboard.tab.Sent" />, () => Translation.text('dashboard.tab.Sent'), 'sent', true);

  static outbox = () => new DashboardTab('outbox', <Translation value="dashboard.tab.Outbox" />, () => Translation.text('dashboard.tab.Outbox'), 'outbox', true);

  static brief = () => new DashboardTab('brief', <Translation value="dashboard.tab.Briefs" />, () => Translation.text('dashboard.tab.Briefs'), 'briefs', true);

  static approved = () => new DashboardTab('approved', <Translation value="dashboard.tab.Approved" />, () => Translation.text('dashboard.tab.Approved'), 'approved', true);

  static archived = () => new DashboardTab('archived', <Translation value="dashboard.tab.Archived" />, () => Translation.text('dashboard.tab.Archived'), 'archived', true);

  static everything = () => new DashboardTab('everything', <Translation value="dashboard.tab.Everything" />, () => Translation.text('dashboard.tab.Everything'), 'everything', true, false, 0, false);

  static teamInbox = pageNumber => new DashboardTab('inbox', <Translation value="dashboard.tab.Inbox" />, () => Translation.text('dashboard.tab.Inbox'), 'inbox', true, true, pageNumber);

  static teamSent = pageNumber => new DashboardTab('sent', <Translation value="dashboard.tab.Sent" />, () => Translation.text('dashboard.tab.Sent'), 'sent', true, true, pageNumber);

  static teamOutbox = pageNumber => new DashboardTab('outbox', <Translation value="dashboard.tab.Outbox" />, () => Translation.text('dashboard.tab.Outbox'), 'outbox', true, true, pageNumber);

  static teamApproved = pageNumber => new DashboardTab('approved', <Translation value="dashboard.tab.Approved" />, () => Translation.text('dashboard.tab.Approved'), 'approved', true, true, pageNumber);

  static teamEverything = pageNumber => new DashboardTab('everything', <Translation value="dashboard.tab.Everything" />, () => Translation.text('dashboard.tab.Everything'), 'everything', true, true, pageNumber, false);
}

export default DashboardTab;
