/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import { Enum } from '@pageproof/sdk';
import HorizontalDelimiter from '../HorizontalDelimiter';
import MarkUnmarked from '../Comment/Icon/MarkUnmarked';
import MarkTodo from '../Comment/Icon/MarkTodo';
import PrivateComment from '../Comment/Icon/PrivateComment';
import MarkDone from '../Comment/Icon/MarkDone';
import Agree from '../Comment/Agree';
import Attach from '../Comment/Icon/Attach';
import Replies from '../Comment/Icon/Replies';
import CommentLabel from '../Comment/Icon/CommentLabel';
import Devices from '../Comment/Icon/Devices';
import Person from '../Decisions/Icons/Person';
import SearchIcon from '../Icon/SearchIcon';
import Tooltip from '../Tooltip';
import Translation from '../Text/Translation';
import Text from './Text';
import FilterUserPopover from './FilterUserPopover';
import FilterPopover from './FilterPopover';
import Avatar from '../../containers/NextAvatar';
import Input from '../Input';
import CommentListOptions from '../CommentListOptions';
import useUserPreferenceColors from '../../hooks/useUserPreferenceColors';
import css from './CommentsHeader.scss';
import IconButton from '../Button/IconButton';
import CheckForOverflow from '../CheckForOverflow';
import DeviceCategoryIcon from '../DeviceCategoryIcon';
import getDownloadRowData from './getDownloadRowData';
import ImportedCommentAvatar from '../ImportedCommentAvatar';

const markIconMap = {
  unmark: MarkUnmarked,
  'mark.todo': MarkTodo,
  'mark.done': MarkDone,
};

function CommentsHeader({
  size,
  filter,
  comments,
  privates,
  unmarked,
  todos,
  dones,
  agrees,
  notAgrees,
  attachments,
  repliesCount,
  privateRepliesCount,
  commentLabels,
  deviceCategoryCounts,
  importFileNameCounts,
  webPageCounts,
  commentedPages,
  marks,
  onFilter,
  onClearFilter,
  onMark,
  mentionedUsers,
  commentByUsers,
  filteredMentionId,
  filteredLabelId,
  filteredDeviceCategory,
  filteredImportFileName,
  filteredWebPage,
  filteredPage,
  filteredUserId,
  filteredSearchText,
  switchPage,
  isCompareMode,
  isVideoOrAudio,
  proofFileCategory,
  commentOrder,
  onChangeCommentOrder,
  getDownloadComments,
  proofTitle,
  framesPerSecond,
}) {
  const [activatedSearchBox, setActivatedSearchBox] = useState(false);
  const [searchInput, setSearchInput] = useState(filteredSearchText || '');
  const searchBoxInputEl = useRef(null);
  const delimiter = <div style={{ width: size * 0.7 }} />;
  const commentedPageCount = commentedPages ? commentedPages.length > 1 : 0;
  const canShowMentionFilter = id => id === 'mention' && mentionedUsers && mentionedUsers.length > 0;
  const canShowPageFilter = id => (
    (id === 'pages' && commentedPageCount) ||
    (id === 'web-pages' && Object.keys(webPageCounts).length > 1)
  );
  const searchCommentTexts = useRef(window.debounce(onFilter, 1000)).current;
  const colors = useUserPreferenceColors();

  const getActiveColor = (nextFilter) => {
    switch (nextFilter.id) {
    case 'agrees': {
      return filter === nextFilter.id
        ? '#f6a500'
        : '#aaa';
    }
    default:
      return '#aaa';
    }
  };

  const getActiveHoverColor = (nextFilter) => {
    if (filter === nextFilter.id) {
      switch (nextFilter.id) {
      case 'label': {
        return (filteredLabelId === 'approved')
          ? '#138b3b'
          : '#f6a500';
      }
      case 'device-category':
        return '#6a6a6a';
      default:
        return nextFilter.color;
      }
    } else {
      switch (nextFilter.id) {
      case 'agrees': {
        return '#919191';
      }
      default:
        return nextFilter.color;
      }
    }
  };

  const getCount = (id, count) => {
    switch (id) {
    case 'label': {
      if (filter === 'label' && commentLabels) {
        return commentLabels[filteredLabelId];
      } else if (commentLabels) {
        return !!(commentLabels.approved || commentLabels.highlight);
      }
      break;
    }
    case 'device-category': {
      if (filter === 'device-category' && deviceCategoryCounts) {
        return deviceCategoryCounts[filteredDeviceCategory];
      } else if (deviceCategoryCounts) {
        return Object.keys(deviceCategoryCounts).length;
      }
      break;
    }
    case 'import-file-name': {
      if (importFileNameCounts) {
        return Object.keys(importFileNameCounts).length;
      }
      break;
    }
    default:
      return count;
    }
    return null;
  };

  const getOptions = (id) => {
    switch (id) {
    case 'agrees':
    case 'no-agrees': {
      const agreesFilterOptions = [
        {
          id: 'agrees',
          label: <Translation value="comment-header.popover.agrees" />,
          selected: filter === 'agrees',
          icon: <Agree
            active
            readOnly
            size={20}
          />,
        }];
      if (notAgrees) {
        agreesFilterOptions.push({
          id: 'no-agrees',
          label: <Translation value="comment-header.popover.no-agrees" />,
          selected: filter === 'no-agrees',
          icon: <Agree
            readOnly
            size={20}
          />,
        });
      }
      return agreesFilterOptions;
    }
    case 'label': {
      const commentLabelsArr = [];
      Object.keys(commentLabels).forEach((label) => {
        if (commentLabels[label]) {
          const color = label === 'approved' ? '#138b3b' : '#f6a500';
          commentLabelsArr.push({
            id: `label:${label}`,
            label: <Translation value={`comment-header.popover.${label}`} />,
            selected: filteredLabelId === label,
            icon: <CommentLabel
              activeColor={color}
              readOnly
              active
            />,
          });
        }
      });
      return commentLabelsArr;
    }
    case 'pages': {
      commentedPages.forEach((page) => {
        page.selected = page.id === Number(filteredPage); // eslint-disable-line
      });
      return commentedPages;
    }
    case 'mark': {
      const onCurrentVersion = isCompareMode
        ? (
          <Text className={css.MarkAll__option__label}>
            <Translation value="comments-header.mark-unmark.on-current-version" />
          </Text>
        )
        : null;

      return marks.map(({ key, type, state, action }) => {
        const actionType = state ? `${action}.${type}` : action;
        const MarkComponent = markIconMap[actionType];

        return {
          id: key,
          label: (
            <div className={css.MarkAll__option}>
              <div>
                <Translation
                  value={`comments-header.${actionType}.tooltip`}
                  params={{ onCurrentVersion }}
                />
              </div>
              {MarkComponent && <MarkComponent active light />}
            </div>
          ),
        };
      });
    }
    case 'device-category': {
      return Object.keys(deviceCategoryCounts).map(label => ({
        id: `device-category:${label}`,
        label: <Translation value={`comment-header.popover.devices.${label}`} />,
        selected: filteredDeviceCategory === label,
        icon: <DeviceCategoryIcon
          deviceCategory={label}
          readOnly
          active
        />,
      }));
    }
    case 'import-file-name': {
      return Object.keys(importFileNameCounts).sort().map(fileName => ({
        id: fileName,
        label: fileName,
        selected: filteredImportFileName === fileName,
        tooltip: fileName,
      }));
    }
    case 'web-pages': {
      return Object.keys(webPageCounts).sort().map(page => ({
        id: page,
        label: page,
        selected: page === filteredWebPage,
        tooltip: page,
      }));
    }
    default:
      return null;
    }
  };

  const commentsCount = privates ? comments - privates : comments;

  /* eslint-disable no-multi-spaces */
  const types = [
    ['comments',      commentsCount, null,             null,            null],
    ['unmarked',      unmarked,      MarkUnmarked,     'unmarked',      colors.unmarked.light],
    ['todo',          todos,         MarkTodo,         'todo',          colors.todo.light],
    ['done',          dones,         MarkDone,         'done',          colors.done.light],
  ];

  const nextFilters = [
    {
      count: commentedPageCount,
      Icon: null,
      id: 'pages',
      color: 'green',
      heading: <Translation value="comments-header.filter.heading.pages" />,
      allowMultiSelect: true,
      onFilter: (pageNumber) => {
        switchPage({ pageNumber });
        onFilter(`pages:${pageNumber}`);
      },
    },
    {
      count: webPageCounts && Object.keys(webPageCounts).length > 1,
      Icon: null,
      id: 'web-pages',
      color: 'green',
      heading: <Translation value="comments-header.filter.heading.pages" />,
      allowMultiSelect: false,
      onFilter: (page) => {
        onFilter(`web-pages:${page}`);
      },
    },
    {
      count: filter === 'agrees' ? agrees : !!agrees,
      Icon: Agree,
      hide: filter === 'no-agrees' && notAgrees,
      id: 'agrees',
      color: null,
      heading: <Translation value="comments-header.filter.heading.agrees" />,
      allowMultiSelect: false,
    },
    {
      count: filter === 'no-agrees' ? notAgrees : true,
      Icon: Agree,
      hide: filter !== 'no-agrees',
      id: 'no-agrees',
      color: 'grey',
      heading: <Translation value="comments-header.filter.heading.agrees" />,
      allowMultiSelect: false,
    },
    {
      count: attachments,
      Icon: Attach,
      id: 'attachments',
      color: null,
      allowMultiSelect: false,
      className: css.CommentsHeader__filter__button__attachments,
    },
    {
      count: mentionedUsers && mentionedUsers.length,
      Icon: null,
      id: 'mention',
      color: 'green',
      allowMultiSelect: false,
    },
    {
      count: commentByUsers && commentByUsers.length > 1,
      Icon: null,
      id: 'user',
      color: 'green',
      allowMultiSelect: false,
    },
    {
      count: getCount('label'),
      Icon: CommentLabel,
      id: 'label',
      color: null,
      heading: <Translation value="comments-header.filter.heading.box-fill" />,
      allowMultiSelect: false,
      onClick: () => {},
    },
    {
      count: getCount('device-category'),
      Icon: props => (
        filteredDeviceCategory
          ? <DeviceCategoryIcon
            {...props}
            deviceCategory={filteredDeviceCategory}
          />
          : <Devices {...props} />
      ),
      id: 'device-category',
      color: 'grey',
      heading: <Translation value="comments-header.filter.heading.devices" />,
      allowMultiSelect: false,
      onClick: () => {},
    },
    {
      count: repliesCount,
      Icon: Replies,
      id: 'replies',
      color: null,
    },
    {
      count: privates + privateRepliesCount,
      Icon: PrivateComment,
      id: 'private',
      color: null,
    },
    {
      name: 'importFileName',
      count: getCount('import-file-name'),
      Icon: null,
      id: 'import-file-name',
      color: 'green',
      heading: <Translation value="comments-header.filter.heading.import-file-name" />,
      allowMultiSelect: false,
      onFilter: (fileName) => {
        onFilter(`import-file-name:${fileName}`);
      },
    },
    {
      count: true,
      Icon: SearchIcon,
      iconSize: size * 0.9,
      hide: !comments || comments.length < 2,
      id: 'search',
      color: null,
      onClick: () => setActivatedSearchBox(!activatedSearchBox),
    },
  ];

  const handleSearchingInput = (value) => {
    setSearchInput(value);
    const trimmedText = value.trim();
    if (filteredSearchText !== trimmedText && (trimmedText === '' || trimmedText.length > 1)) {
      searchCommentTexts(`search:${trimmedText}`);
    }
  };

  const downloadCommentsAsCSV = () => {
    const downloadComments = getDownloadComments();
    const excludedColumns = [];

    if (!downloadComments.some(comment => comment.isPrivate || (comment.replies && comment.replies.some(reply => reply.isPrivate)))) {
      excludedColumns.push('Is private');
    }

    if (!downloadComments.some(comment => comment.sourceMetadata && comment.sourceMetadata.pdfImport)) {
      excludedColumns.push('Source file');
    }

    const csvObj = downloadComments.reduce((rows, comment) => {
      const parent = getDownloadRowData(comment, proofFileCategory, undefined, excludedColumns, framesPerSecond);
      const replies = comment.replies.length
        ? comment.replies.map(reply => getDownloadRowData(reply, proofFileCategory, comment, excludedColumns, framesPerSecond))
        : [];
      return [...rows, parent, ...replies];
    }, []);
    const csvFile = window.generalfunctions_csv(csvObj);

    window.downloadFile(`${proofTitle}.csv`, new window.Blob([csvFile]));
  };

  useEffect(() => {
    setActivatedSearchBox(filter === 'search');

    if (filter !== 'search') {
      setSearchInput('');
    }
  }, [filter]);

  useEffect(() => {
    if (comments > 0 && filter === 'search') {
      setSearchInput(filteredSearchText);
      searchCommentTexts(`search:${filteredSearchText}`);
    }
  }, [comments]);

  useEffect(() => {
    if (activatedSearchBox) {
      searchBoxInputEl.current.focus();
    }
  }, [activatedSearchBox]);

  /* eslint-enable no-multi-spaces */
  return (
    <div
      data-walkthrough-hook="proof-comments-header"
      className={css.CommentsHeader}
    >
      <div className={css.CommentsHeader__bar}>
        <div className={css.Left}>
          <HorizontalDelimiter
            delimiter={delimiter}
          >
            {types.map(([name, count, Icon, id, color]) => (
              (count || name === 'comments') &&
                <Tooltip
                  key={name}
                  title={<Translation value={`comments-header.${name}.tooltip`} />}
                  down
                  center
                  maxWidth={265}
                  offset={15}
                >
                  <div
                    className={css.CommentsHeader__filter}
                  >
                    <div
                      className={css.CommentsHeader__filter__backdrop}
                      style={{
                        backgroundColor: (filter === id && (Icon || color))
                          ? color || Icon.iconProps.activeColor
                          : 'transparent',
                      }}
                    />
                    <div className={css.CommentsHeader__filter__button}>
                      {Icon
                        ? (
                          <Icon
                            active
                            hover={filter === id}
                            label={count}
                            size={size}
                            onClick={() => onFilter(id)}
                          />
                        )
                        : (
                          <Fragment>
                            {!id &&
                              <Text
                                active={filter === id}
                                onClick={() => onFilter(id)}
                              >
                                {count > 1 && <Translation value="comments.count.other" params={{ count }} />}
                                {count === 1 && <Translation value="comments.count.one" params={{ count }} />}
                                {count === 0 && <Translation value="comments.count.zero" />}
                              </Text>
                            }
                          </Fragment>
                        )
                      }
                    </div>
                  </div>
                </Tooltip>
            ))}
          </HorizontalDelimiter>
        </div>
        <div className={css.Right}>
          {marks &&
            <CheckForOverflow>
              {wouldOverflow => (
                <FilterPopover
                  variant="dark"
                  options={getOptions('mark')}
                  canCancel
                  onAddFilter={onMark}
                >
                  {wouldOverflow
                    ? (
                      <IconButton
                        src="/img/icons/options.svg"
                        className={css.CommentsHeader__optionsIcon}
                      />
                    )
                    : (
                      <Text>
                        {marks.every(v => v.action === 'unmark')
                          ? <Translation value="comments-header.unmark-all" />
                          : <Translation value="comments-header.mark-all" />}
                      </Text>
                    )
                  }
                </FilterPopover>
              )}
            </CheckForOverflow>
          }
        </div>
      </div>
      <div className={css.HorizontalLine} />
      <div className={css.CommentsHeader__bar}>
        <div className={css.Left}>
          <HorizontalDelimiter
            delimiter={delimiter}
          >
            {!activatedSearchBox && nextFilters.map(nextFilter => (
              nextFilter.count && !nextFilter.hide &&
                <Tooltip
                  key={nextFilter.id}
                  title={<Translation value={`comments-header.${nextFilter.id}.tooltip`} />}
                  down
                  center
                  maxWidth={265}
                  offset={15}
                >
                  {tooltip => (
                    <div className={css.CommentsHeader__filter}>
                      <div
                        className={css.CommentsHeader__filter__backdrop}
                        style={{
                          backgroundColor: (filter === nextFilter.id && (nextFilter.Icon || nextFilter.color))
                            ? nextFilter.color || nextFilter.Icon.iconProps.activeColor
                            : 'transparent',
                        }}
                      />
                      <div
                        className={css.CommentsHeader__filter__button}
                        onClick={() => tooltip.hide()}
                      >
                        {nextFilter.Icon &&
                          <FilterPopover
                            variant="light"
                            heading={nextFilter.heading}
                            options={getOptions(nextFilter.id)}
                            canClear={nextFilter.id === filter}
                            onRemoveAllFilters={onClearFilter}
                            multiSelect={nextFilter.allowMultiSelect}
                            onAddFilter={(id) => {
                              onFilter(id);
                            }}
                          >
                            <nextFilter.Icon
                              active={filter !== 'no-agrees'}
                              hover={filter === nextFilter.id}
                              size={nextFilter.iconSize || size}
                              activeColor={getActiveColor(nextFilter)}
                              activeHoverColor={getActiveHoverColor(nextFilter)}
                              onClick={nextFilter.onClick
                                ? nextFilter.onClick
                                : () => onFilter(nextFilter.id)
                              }
                              className={nextFilter.className}
                            />
                          </FilterPopover>
                        }
                        {canShowMentionFilter(nextFilter.id) &&
                          <FilterUserPopover
                            id={nextFilter.id}
                            label={<Translation value="comments-header.mentions.label" />}
                            icon="@"
                            active={filter === nextFilter.id}
                            onClick={(group, mentionedUser) => {
                              onFilter(`mention:${mentionedUser.id}`);
                            }}
                            onClearFilter={onClearFilter}
                            users={mentionedUsers}
                            selectedUserId={filteredMentionId}
                          />
                        }
                        {nextFilter.id === 'user' &&
                          <FilterUserPopover
                            id={nextFilter.id}
                            label={<Translation value="comments-header.users.label" />}
                            icon={
                              // eslint-disable-next-line no-nested-ternary
                              (filteredUserId
                                ? (filteredUserId.startsWith('pdfAuthor:')
                                  ? (
                                    <ImportedCommentAvatar
                                      avatarSize={size}
                                      active
                                    />
                                  )
                                  : (
                                    <Avatar
                                      id={filteredUserId}
                                      size={size}
                                      active
                                    />
                                  )
                                )
                                : <Person size={size} />
                              )
                            }
                            active={filter === nextFilter.id}
                            onClick={(group, commentByUser) => {
                              onFilter(`user:${commentByUser.id}`);
                            }}
                            onClearFilter={onClearFilter}
                            users={commentByUsers}
                            selectedUserId={filteredUserId}
                          />
                        }
                        {nextFilter.id === 'import-file-name' &&
                        <FilterPopover
                          variant="light"
                          options={getOptions(nextFilter.id)}
                          canClear={filter === nextFilter.id}
                          onRemoveAllFilters={onClearFilter}
                          heading={nextFilter.heading}
                          multiSelect={nextFilter.allowMultiSelect}
                          onAddFilter={nextFilter.onFilter}
                        >
                          <Text
                            active={filter === nextFilter.id}
                            theme="green"
                          >
                            PDF
                          </Text>
                        </FilterPopover>
                        }
                        {canShowPageFilter(nextFilter.id) &&
                          <FilterPopover
                            variant="light"
                            options={getOptions(nextFilter.id)}
                            canClear={filter === nextFilter.id}
                            onRemoveAllFilters={onClearFilter}
                            heading={nextFilter.heading}
                            multiSelect={nextFilter.allowMultiSelect}
                            onAddFilter={nextFilter.onFilter}
                          >
                            <Text
                              active={filter === nextFilter.id}
                              theme="green"
                            >
                              <Translation
                                value="default.page-x"
                                params={{ page: filteredWebPage ? '' : filteredPage || '' }}
                              />
                            </Text>
                          </FilterPopover>
                        }
                      </div>
                    </div>
                  )}
                </Tooltip>
            ))}
            <div className={classname(css.SearchBox, { [css.SearchBox__show]: activatedSearchBox })}>
              {activatedSearchBox &&
                <Fragment>
                  <InlineSVG
                    className={css.SearchBox__icon}
                    src="/img/interface/search.svg"
                  />
                  <Input
                    style={{ fontSize: '14px', height: '20px' }}
                    type="text"
                    value={searchInput}
                    placeholder={Translation.text('comments-header.search.placeholder')}
                    onChange={handleSearchingInput}
                    inputRef={searchBoxInputEl}
                  />
                </Fragment>
              }
            </div>
          </HorizontalDelimiter>
        </div>
        <div className={css.Right}>
          <CommentListOptions
            isFiltered={!!filter || activatedSearchBox}
            onClearFilter={() => {
              if (activatedSearchBox) {
                setActivatedSearchBox(!activatedSearchBox);
              }
              onClearFilter();
            }}
            isVideoOrAudio={isVideoOrAudio}
            proofFileCategory={proofFileCategory}
            onChangeCommentOrder={onChangeCommentOrder}
            commentOrder={commentOrder}
            delimiterSize={size}
            isCompareMode={isCompareMode}
            onDownloadComments={downloadCommentsAsCSV}
          />
        </div>
      </div>
    </div>
  );
}

CommentsHeader.defaultProps = {
  size: 20,
};

if (process.env.NODE_ENV === 'development') {
  CommentsHeader.propTypes = {
    size: PropTypes.number,
    filter: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    comments: PropTypes.number,
    unmarked: PropTypes.number,
    todos: PropTypes.number,
    dones: PropTypes.number,
    agrees: PropTypes.number,
    notAgrees: PropTypes.number,
    attachments: PropTypes.number,
    repliesCount: PropTypes.number,
    privateRepliesCount: PropTypes.number,
    commentedPages: PropTypes.arrayOf(PropTypes.object),
    commentLabels: PropTypes.objectOf(PropTypes.any),
    deviceCategoryCounts: PropTypes.objectOf(PropTypes.any),
    importFileNameCounts: PropTypes.objectOf(PropTypes.any),
    webPageCounts: PropTypes.objectOf(PropTypes.any),
    marks: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        action: PropTypes.string,
      })),
      PropTypes.bool,
    ]),
    onFilter: PropTypes.func,
    onMark: PropTypes.func,
    switchPage: PropTypes.func,
    onClearFilter: PropTypes.func,
    mentionedUsers: PropTypes.arrayOf(PropTypes.object),
    commentByUsers: PropTypes.arrayOf(PropTypes.object),
    filteredMentionId: PropTypes.string,
    filteredLabelId: PropTypes.string,
    filteredDeviceCategory: PropTypes.string,
    filteredWebPage: PropTypes.string,
    filteredUserId: PropTypes.string,
    filteredPage: PropTypes.string,
    filteredSearchText: PropTypes.string,
    isCompareMode: PropTypes.bool,
    isVideoOrAudio: PropTypes.bool,
    proofFileCategory: PropTypes.oneOf(Object.values(Enum.FileCategory)),
    commentOrder: PropTypes.objectOf(PropTypes.oneOf(PropTypes.string, PropTypes.function)),
    onChangeCommentOrder: PropTypes.func,
    getDownloadComments: PropTypes.func,
    proofTitle: PropTypes.string,
    framesPerSecond: PropTypes.number,
  };
}

export default CommentsHeader;
