/* Copyright (C) 2024 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useMemo } from 'react';
import { Enum } from '@pageproof/sdk';
import FileDropper from './FileDropper';
import { Translation } from '../../Text';

const ProofTileFileDropper = ({ proof, variant, direction }) => {
  const isBrief = (proof.type || proof.proofType) === Enum.ProofType.BRIEF;
  const hasFile = !!proof.fileId;
  const hasFileError = (
    typeof proof.hasFileError === 'function'
      ? proof.hasFileError()
      : proof.hasFileError
  );

  const tooltipTitle = useMemo(() => {
    if (isBrief) {
      return <Translation value="dashboard.proof.options.upload-proof" />;
    }

    if (hasFileError || !hasFile) {
      return <Translation value="dashboard.proof.options.upload-file" />;
    }

    return <Translation value="dashboard.proof.options.upload-new-version" />;
  }, [isBrief, hasFileError, hasFile]);

  const color = (
    ((hasFile && hasFileError) ||
    (proof.allowReupload && proof.getProcessingProgress() * 100 === 10)) &&
    'red'
  );

  const dropperProps = {};
  const getHighlight = () => {
    if (variant === 'small') {
      return null;
    } else if (isBrief) {
      return 'brief';
    } else if (variant === 'list' || variant === 'info-pane') {
      return (hasFileError && 'error') || 'new-version';
    } else {
      return !hasFile && 'error';
    }
  };

  if (hasFileError || proof.allowReupload) {
    if (isBrief) {
      // Retry brief file
      dropperProps.type = 'brief';
      dropperProps.parameters = {
        updateProof: {
          id: proof.id,
        },
      };
    } else {
      // File error, retry (for a proof)
      dropperProps.parameters = {
        updateProof: {
          id: proof.id,
        },
        backUrl: window.__pageproof_bridge__.$location.url(),
        showWorkflowReadonly: !!proof.status, // Make workflow section readonly, only if proof has moved on to next statuses
      };
      dropperProps.initialize = {
        proof: {
          id: proof.id,
          name: proof.name,
          reference: proof.reference,
          integrationReferences: proof.integrationReferences,
          tags: proof.tags,
          messageToReviewers: proof.messageToReviewers,
          canDownload: proof.canDownload,
          commentVisibility: proof.commentVisibility,
          isPublic: proof.isPublic,
          canBePublic: proof.canBePublic,
          availableWorkflowRoles: proof.availableWorkflowRoles,
          canAddChecklist: proof.canAddChecklist,
          dueDate: proof.dueDate,
        },
        checklist: proof.checklist,
        workflow: proof.workflowId
          ? { id: proof.workflowId }
          : undefined,
        owners: proof.owners.map(owner => owner.email),
      };
    }
  } else if (isBrief) {
    // Upload new version (for a brief)
    dropperProps.parameters = {
      backUrl: window.__pageproof_bridge__.$location.url(),
      attachNewVersion: {
        proofId: proof.id,
        collectionId: proof.groupId,
        dueDate: proof.dueDate,
      },
      defaults: {
        name: proof.name,
        reference: proof.reference,
        tags: proof.tags,
        canDownload: proof.canDownload,
        dueDate: proof.usePreviousVersionDueDate ? proof.dueDate : undefined,
      },
    };
    dropperProps.initialize = {
      workflow: {
        steps: [
          {
            users: [
              {
                email: proof.owners[0].email,
                role: 'approver',
                permissions: {
                  inviter: true,
                },
              },
            ],
          },
        ],
      },
      owners: [
        proof.recipientEmail,
      ],
    };
  } else {
    // Upload new version (for a proof)
    dropperProps.parameters = {
      backUrl: window.__pageproof_bridge__.$location.url(),
      attachNewVersion: {
        proofId: proof.id,
        workflowId: proof.workflowId,
        collectionId: proof.groupId,
        checklist: proof.checklist,
        canAddChecklist: proof.canAddChecklist,
        availableWorkflowRoles: proof.availableWorkflowRoles,
        canHaveCommentVisibility: proof.canHaveCommentVisibility,
        dueDate: proof.dueDate,
      },
      defaults: {
        name: proof.name,
        reference: proof.reference,
        tags: proof.tags,
        messageToReviewers: proof.messageToReviewers,
        canDownload: proof.canDownload,
        integrationReferences: proof.integrationReferences,
        isPublic: proof.isPublic,
        commentVisibility: proof.commentVisibility,
        dueDate: proof.usePreviousVersionDueDate ? proof.dueDate : undefined,
      },
    };
    dropperProps.initialize = {
      checklist: proof.checklist && {
        ...proof.checklist,
        id: undefined,
      },
      workflow: { id: proof.workflowId },
      owners: proof.owners.map(owner => owner.email),
    };
  }

  return (
    <FileDropper
      tooltipTitle={tooltipTitle}
      variant={variant}
      color={color}
      compact
      multiple={false}
      highlight={getHighlight()}
      direction={direction}
      {...dropperProps}
    />
  );
};

export default ProofTileFileDropper;
