/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, { useState, useEffect, Fragment, useRef } from 'react';
import InlineSVG from 'jacobmarshall-react-inline-svg';
import classname from 'classname';
import useKeyboardShortcut from '../../hooks/useKeyboardShortcut';
import EncryptedThumbnail from '../EncryptedThumbnail';
import css from './UniversalSearch.scss';
import { getClient } from '../../util/graphql';
import getProofSuggestions from '../../util/gql/getProofSuggestions';
import { TranslatedProps, Translation } from '../Text';

const UniversalSearch = () => {
  const { $location, $rootScope } = window.__pageproof_bridge__;

  const [client] = useState(() => getClient());
  const [isVisible, setIsVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectionIndex, setSelectionIndex] = useState(0);
  const searchIndexRef = useRef({ latest: 0, rendered: 0 });

  const modifySelectionIndex = (modifier, suggestionCount = suggestions.length) => {
    setSelectionIndex(Math.max(0, Math.min(suggestionCount - 1, selectionIndex + modifier)));
  };

  useKeyboardShortcut('esc', (event) => {
    event.preventDefault();
    setIsVisible(false);
  });

  useKeyboardShortcut('/', (event) => {
    event.preventDefault();
    setSearchQuery('');
    setIsVisible(!isVisible);
  });

  useEffect(() => {
    const searchIndex = searchIndexRef.current.latest + 1;
    searchIndexRef.current.latest = searchIndex;

    if (searchQuery.length) {
      // console.time(`search (${searchIndex}) ${searchQuery}`);
      client.query({
        query: getProofSuggestions,
        variables: { search: searchQuery },
        fetchPolicy: 'no-cache',
      })
        .then((result) => {
          if (searchIndex < searchIndexRef.current.rendered) return;

          // console.timeEnd(`search (${searchIndex}) ${searchQuery}`);
          searchIndexRef.current.rendered = searchIndex;

          const { proofSuggestions } = result.data;
          setSuggestions(proofSuggestions.map(proofSuggestion => ({
            id: proofSuggestion.proof.id,
            name: proofSuggestion.proof.name,
            versionNumber: proofSuggestion.proof.versionNumber,
            status: proofSuggestion.proof.status,
            statusMessage: proofSuggestion.proof.statusMessage,
            thumbnail: proofSuggestion.proof.file && proofSuggestion.proof.file.id,
            collection: proofSuggestion.proof.collection && proofSuggestion.proof.collection.name,

            action: 'Open',
            onAction() {
              $location.url(`/proof-screen/${proofSuggestion.proof.id}`);
              $rootScope.$apply();
            },
          })));
          modifySelectionIndex(0, proofSuggestions.length);
        });
    } else {
      setSuggestions([]);
      modifySelectionIndex(0);
      searchIndexRef.current.rendered = searchIndex;
    }
  }, [searchQuery]);

  let ghost = '';
  if (suggestions[selectionIndex]) {
    const selectedSuggestion = suggestions[selectionIndex];
    const selectedSuggestionStartIndex = selectedSuggestion.name.toLowerCase().indexOf(searchQuery.toLowerCase());
    const selectedSuggestionEndIndex = selectedSuggestion.name.indexOf(' ', selectedSuggestionStartIndex + searchQuery.length);

    if (selectedSuggestionStartIndex !== -1) {
      ghost = selectedSuggestion.name.substring(
        selectedSuggestionStartIndex + searchQuery.length,
        selectedSuggestionEndIndex !== -1 ? selectedSuggestionEndIndex : undefined,
      );
    }
  }

  return (
    <Fragment>
      {/* Eslint wants us to put extra props onto this div (for accessability) however they're not needed. */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={classname(css.UniversalSearchBackdrop, {
          [css['UniversalSearchBackdrop--isVisible']]: isVisible,
        })}
        onClick={() => {
          setIsVisible(false);
        }}
      />
      {isVisible && (
        <div className={css.UniversalSearch}>
          <div className={css.UniversalSearch__inner}>
            <InlineSVG
              src="/img/interface/search.svg"
              className={css.UniversalSearch__searchIcon}
            />
            <div className={css.UniversalSearch__inputGhost}>
              <span style={{ opacity: 0 }}>{searchQuery}</span>
              {searchQuery.length >= 1 && <span>{ghost}</span>}
            </div>
            <TranslatedProps placeholder="default.placeholder.search">
              <input
                className={css.UniversalSearch__inputField}
                id="universal-search"
                autoComplete="no-thank-you"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="false"
                autoFocus // eslint-disable-line
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
                onKeyDown={(event) => {
                  switch (event.key) {
                  case 'ArrowUp': {
                    event.preventDefault();
                    modifySelectionIndex(-1);
                    break;
                  }
                  case 'ArrowDown': {
                    event.preventDefault();
                    modifySelectionIndex(1);
                    break;
                  }
                  case 'Tab':
                  case 'ArrowRight': {
                    if (ghost && event.target.selectionEnd === searchQuery.length) {
                      event.preventDefault();
                      setSearchQuery(searchQuery + ghost);
                    }
                    break;
                  }
                  case '/': {
                    if (searchQuery.length === 0) {
                      setIsVisible(false);
                    }
                    break;
                  }
                  case 'Escape': {
                    setIsVisible(false);
                    break;
                  }
                  case 'Enter': {
                    suggestions[selectionIndex].onAction();
                    setIsVisible(false);
                    break;
                  }
                  default: {
                    break;
                  }
                  }
                }}
              />
            </TranslatedProps>
            {!!suggestions.length && (
              <div className={css.UniversalSearch__suggestionsSummary}>
                {suggestions.length > 1
                  ? (
                    <Translation
                      value="universal-search.suggestion-summary.multi"
                      params={{ count: suggestions.length }}
                    />
                  )
                  : <Translation value="universal-search.suggestion-summary" />
                }
              </div>
            )}
            <div className={css.UniversalSearch__suggestionsList}>
              {suggestions.map((suggestion, index) => (
                <button
                  type="button"
                  key={suggestion.id}
                  className={classname(css.UniversalSearch__suggestion, {
                    [css['UniversalSearch__suggestion--selected']]: selectionIndex === index,
                  })}
                  onClick={(event) => {
                    event.preventDefault();
                    suggestion.onAction();
                    setIsVisible(false);
                  }}
                  onMouseEnter={() => {
                    setSelectionIndex(index);
                  }}
                  onMouseLeave={() => {
                    // noop
                  }}
                  onFocus={() => {
                    setSelectionIndex(index);
                  }}
                >
                  <div className={classname(css.UniversalSearch__suggestion__thumbnail)}>
                    {suggestion.thumbnail && (
                      <EncryptedThumbnail
                        id={suggestion.thumbnail}
                        alt={suggestion.name}
                        width={30}
                        height={40}
                        style={{ objectFit: 'contain' }}
                      />
                    )}
                  </div>
                  <div className={css.UniversalSearch__suggestion__name}>
                    {suggestion.name}
                    {suggestion.versionNumber
                      ? (
                        <span className={css.UniversalSearch__suggestion__versionNumber}>
                          v
                          {suggestion.versionNumber}
                        </span>
                      )
                      : null
                    }
                    {suggestion.collection && (
                      <span className={css.UniversalSearch__suggestion__collection}>
                        {' – '}
                        {suggestion.collection}
                      </span>
                    )}
                    <div className={classname(css.UniversalSearch__suggestion__status, css[`UniversalSearch__suggestion__status--${suggestion.status}`])}>
                      {suggestion.statusMessage}
                    </div>
                  </div>
                  {(selectionIndex === index && suggestion.action) && (
                    <div className={css.UniversalSearch__suggestion__action}>
                      {suggestion.action}
                    </div>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UniversalSearch;
