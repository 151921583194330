/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardProofTilesContainer from '../DashboardProofTiles';
import DashboardNavigationContainer from '../DashboardNavigation';
import RecentProofs from '../../components/RecentProofs';
import PaginationContainer from '../Pagination';
import ProofSearchContainer from '../ProofSearch';
import NoProofMessage from './NoProofMessage';
import ShowMore from './ShowMore';
import SideBarTheme from '../../hoc/Contexts/SideBarTheme';
import FileDropper from '../../components/ProofSetup/components/FileDropper';
import ProofsSelectionSummary from '../../components/ProofsSelectionSummary';
import { getSearchTab } from './ActionsInternal';
import css from './Dashboard.scss';
import { Spinner } from '../../components/Spinner';
import { Translation } from '../../components/Text';

function Dashboard(props) {
  return (
    <SideBarTheme.Provider value={props.color || 'grey'}>
      <ProofSearchContainer {...props.searchProps} />
      <DashboardNavigationContainer
        {...props.navigation}
        from={props.from}
        color={props.color}
      />
      {props.selectedTab === 'inbox' && !props.manageItem &&
        <RecentProofs userId={props.userId} />
      }
      {props.manageItem !== 'compare' &&
        <ProofsSelectionSummary
          visible={props.manageItem}
          selectedTab={(props.isStaticTab || props.isCollectionTab) ? props.selectedTab : 'search'}
          selectedProofsCount={props.selectedProofsCount}
          selectableProofsCount={props.selectableProofsCount}
          onToggleSelectionOfAllProofs={props.onToggleSelectionOfAllProofs}
          searchStr={getSearchTab(props.searchProps.search, props.searchProps.selectedFilters)}
        />
      }
      <div className={css.Dashboard__proofs}>
        {((props.isLoading && props.noProofs) || !props.onceLoaded) && (
          <div className={css.Dashboard__proofs__loader}>
            <Spinner size={60} />
            <p>
              <Translation value="dashboard.loading.message" />
            </p>
          </div>
        )}

        {(props.noProofs || props.noProofsWithFilter) && !props.isLoading && props.onceLoaded && (
          <NoProofMessage
            tab={props.selectedTab}
            from={props.from}
            isStaticTab={props.isStaticTab}
            isCollectionTab={props.isCollectionTab}
            noProofs={props.noProofs}
            noProofsWithFilter={props.noProofsWithFilter}
            isLoading={props.isLoading}
            canUpload={props.canShowUploader}
            searchProps={props.searchProps}
          />
        )}
        {props.canShowUploader && (
          <FileDropper
            type={props.selectedTab === 'brief' ? 'brief' : 'proof'}
            variant={props.uploaderProps.isTile ? 'tile' : 'list'}
            compact={false}
            multiple
            tooltipTitle={props.uploaderTooltipTitle}
            parameters={props.isCollectionTab
              ? {
                addToCollection: {
                  id: props.uploaderProps.groupId,
                  name: props.uploaderProps.groupName,
                },
              }
              : undefined
            }
          />
        )}
        <DashboardProofTilesContainer
          {...props.tabProofs}
          from={props.from}
        />
        {props.from === 'team-dashboard' &&
          <PaginationContainer {...props.paginationProps} />
        }
        {props.from === 'dashboard' && !props.noProofsWithFilter &&
          <ShowMore {...props.showMoreProps} />
        }
      </div>
    </SideBarTheme.Provider>
  );
}

if (process.env.NODE_ENV !== 'production') {
  Dashboard.propTypes = {
    color: PropTypes.string,
    from: PropTypes.string,
  };
}

export default Dashboard;
