/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
/* eslint-disable */

import React, { Fragment } from 'react';
import { ApolloProvider, getClient } from '../../util/graphql';

const Sandbox = () => {
  return (
    <Fragment>
      Hello World!
    </Fragment>
  );
};

const SandboxContainer = () => {
  return (
    <ApolloProvider client={getClient()}>
      <Sandbox />
    </ApolloProvider>
  );
};

export default SandboxContainer;
