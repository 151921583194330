/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

/* eslint-disable no-continue */

import CommentsPaneSpacer from '../components/CommentsPaneSpacer';
import CommentsPanePage from '../components/CommentsPanePage';
import CommentsPaneComment from '../components/CommentsPaneComment';
import CommentsPaneCreateComment from '../components/CommentsPaneCreateComment';

let orderBy;

export function getVirtualizedData(proofCtrl) {
  orderBy = orderBy || window.__pageproof_bridge__.$filter('orderBy');

  const { filter, commentOrder, currentPage, isCreatingComment, temporaryComment } = proofCtrl;

  const data = {
    items: [],
  };

  function add(item) {
    data.items.push(item);
  }

  const pagesByNumber = {};

  const filteredCommentsByPage = {};
  let atLeastOnePageHasCommentsAfterFiltering = false;

  proofCtrl.proof.pages.forEach((page) => {
    pagesByNumber[page.pageNumber] = page;

    if (page.comments.length) {
      const filteredComments = filter
        ? page.comments.filter(comment => filter.fn(comment))
        : page.comments;

      filteredCommentsByPage[page.pageNumber] = filteredComments;

      if (filteredComments.length) {
        atLeastOnePageHasCommentsAfterFiltering = true;
      }
    } else {
      filteredCommentsByPage[page.pageNumber] = [];
    }
  });

  const numberOfPages = proofCtrl.proof.pageCount;
  for (let pageNumber = 0; pageNumber <= numberOfPages; pageNumber += 1) {
    const page = pagesByNumber[pageNumber];

    if (!page) {
      continue;
    }

    let comments = filteredCommentsByPage[pageNumber];

    if (commentOrder) {
      comments = orderBy(
        comments,
        commentOrder.orderCommentsBy,
        commentOrder.isReversedCommentOrder
      );
    }

    const isCreatingCommentOnPage = isCreatingComment && pageNumber === currentPage;

    if (comments.length > 0 || pageNumber === currentPage) {
      const pageComments = comments.slice();

      if (isCreatingCommentOnPage) {
        pageComments.unshift(temporaryComment);
      }

      if (numberOfPages > 1) {
        add({
          key: 'page' + pageNumber,
          type: 'page',
          component: CommentsPanePage,
          props: {
            page,
            comments: pageComments,
            atLeastOnePageHasCommentsAfterFiltering: atLeastOnePageHasCommentsAfterFiltering || isCreatingCommentOnPage,
          },
        });
      }
    }

    if (isCreatingCommentOnPage) {
      add({
        key: 'new',
        type: 'new',
        component: CommentsPaneCreateComment,
        props: {
          pageNumber,
        },
      });
    }

    if (comments.length) {
      comments.forEach((comment) => {
        add({
          key: 'comment' + comment.id,
          type: 'comment',
          component: CommentsPaneComment,
          props: {
            comment,
            page,
          },
        });
      });
    }
  }

  add({
    key: 'spacer',
    type: 'spacer',
    component: CommentsPaneSpacer,
  });

  return data;
}
