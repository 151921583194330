import React, { Fragment } from 'react';
import { gql, getClient, Subscription } from '../../util/graphql';
import PrintActivityTray from '../PrintActivityTray';

const PROGRESS_SUBSCRIPTION = gql`
  subscription ppxapp_onPrintProgressUpdated($id: String!) {
    progress: onPrintProgressUpdated(id: $id) {
      id
      progress
    }
  }
`;

const RESULT_SUBSCRIPTION = gql`
  subscription ppxapp_onPrintFinished($id: String!) {
    result: onPrintFinished(id: $id) {
      id
      url
      name
    }
  }
`;

class PrintActivityContainer extends React.Component {
  state = {
    jobs: [],
    progress: []
  }

  componentDidMount() {
    this.printService().subscribe('tray-container', (jobs) => {
      this.setState({ jobs });
    });
  }

  componentWillUnmount() {
    this.printService().unsubscribe('tray-container');
  }

  printService = () => window.__pageproof_bridge__.printService;

  renderSubscriptions = jobs => jobs.map(job => (
    <Fragment key={job.id}>
      <Subscription
        client={getClient()}
        subscription={PROGRESS_SUBSCRIPTION}
        variables={{ id: job.id }}
        onSubscriptionData={({ subscriptionData }) => this.updateProgress(subscriptionData.data.progress)}
      />
      <Subscription
        client={getClient()}
        subscription={RESULT_SUBSCRIPTION}
        variables={{ id: job.id }}
        onSubscriptionData={({ subscriptionData }) => this.handleResult(subscriptionData.data.result)}
      />
    </Fragment>
  ));

  handleResult = (result) => {
    this.printService().complete(result.id);
    window.downloadURL(result.url, result.name);
  }

  combineJobsAndProgress = (jobs, progress) => jobs.map((job) => {
    const match = progress.filter(Boolean).filter(prog => prog.id === job.id)[0];
    return {
      id: job.id,
      progress: match ? match.progress : 0,
      name: job.name
    };
  });

  updateProgress(jobUpdate) {
    const progress = this.state.progress.filter(p => p.id !== jobUpdate.id);
    progress.push(jobUpdate);
    this.setState({ progress });
  }

  render() {
    const jobs = this.combineJobsAndProgress(this.state.jobs, this.state.progress);
    return (
      <Fragment>
        {this.renderSubscriptions(this.state.jobs)}
        {jobs.length > 0 &&
          <PrintActivityTray jobs={jobs} />
        }
      </Fragment>
    );
  }
}

export default PrintActivityContainer;
