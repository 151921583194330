/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { ApolloProvider, getClient, gql } from '../../util/graphql';

function CommentEvents({ proofId, onEvent }) {
  useSubscription(gql`
    subscription example($proofId: String!) {
      onCommentEvent(proofId: $proofId) {
        ...on CommentCreatedEvent {
          commentId
        }
        ...on CommentEditedEvent {
          commentId
        }
        ...on ReplyCreatedEvent {
          replyId
        }
        ...on ReplyEditedEvent {
          replyId
        }
        ...on CommentDeletedEvent {
          commentId
        }
        ...on ReplyDeletedEvent {
          replyId
        }
        ...on CommentUpdatedEvent {
          commentId
        }
        ...on CommentsUpdatedEvent {
          proofId
        }
      }
    }
  `, {
    variables: {
      proofId,
    },
    onSubscriptionData(options) {
      onEvent(options.subscriptionData.data.onCommentEvent);
    },
    shouldResubscribe: true,
  });

  return null;
}

export default function (props) {
  return (
    <ApolloProvider client={getClient()}>
      <CommentEvents {...props} />
    </ApolloProvider>
  );
}
