/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { split } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { Subscription, Query } from '@apollo/react-components';
import { getMainDefinition } from 'apollo-utilities';
import { HttpLink } from 'apollo-link-http';
import { useQuery, ApolloProvider } from '@apollo/react-hooks';
import { setContext } from 'apollo-link-context';
import env from '../../../shared/env';
import { getSavedSession } from '../../../shared/session';

const authHeaders = (method) => {
  const timestamp = Date.now();
  const session = getSavedSession();

  // eslint-disable-next-line no-undef
  const hmac = forge.hmac.create();
  hmac.start('sha256', session.sharedKey);
  hmac.update(session.token);
  hmac.update(method);
  hmac.update(String(timestamp));
  hmac.update('/graphql');
  // eslint-disable-next-line no-undef
  const final = forge.util.encode64(hmac.digest().data);

  const headers = {
    'x-timestamp': timestamp,
    'x-authorization-token': session.token,
    'x-authorization-hmac': final,
  };
  return headers;
};

function useQueryEnhanced(query, subscription, variables, reduce) {
  const { subscribeToMore, ...enhanced } = useQuery(query, {
    variables,
    onCompleted: () => {
      subscribeToMore({
        document: subscription,
        variables,
        updateQuery: (previousResult, { subscriptionData }) => (
          (reduce && reduce(previousResult, subscriptionData.data)) ||
            subscriptionData.data
        ),
      });
    },
  });
  return enhanced;
}

function createClient(queryUri, subscriptionUri) {
  const session = getSavedSession();
  if (!session || !session.userId) {
    // We do not want to create a client without auth headers
    // Throwing here means that any place that tries to will fail without breaking subsequent graph requests
    throw new Error('Attempted to create graphql client without a logged in user');
  }

  const wsLink = new WebSocketLink({
    uri: subscriptionUri,
    options: {
      reconnect: true,
      lazy: false,
      // Use connection params for 'headers' because websockets don't support custom headers.
      connectionParams: () => ({
        headers: {
          ...authHeaders('GET'),
        },
      }),
    },
  });

  const httpLink = new HttpLink({
    uri: queryUri,
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [],
      },
    },
  });

  const cache = new InMemoryCache({
    fragmentMatcher,
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'none',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  };

  // We use the context link to set the auth headers every query request to make sure the hmac is as recent as possible
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...authHeaders('POST'),
    },
  }));

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') ||
        (definition.name && definition.name.value.startsWith('ws_'))
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
  });

  return client;
}

let client;

export function getClient() {
  if (!client) {
    client = createClient(env.graphite_query_url, env.graphite_subscription_url);
  }
  return client;
}

export {
  gql,
  ApolloProvider,
  useQuery,
  useQueryEnhanced,
  Query,
  Subscription,
};
