/* Copyright (C) 2022 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { getClient } from '../util/graphql';
import getUserPreferencesQuery from '../util/gql/getUserPreferences';
import subscribeUserPreferences from '../util/gql/subscribeUserPreferences';
import { getSavedSession } from '../../../shared/session';
import { watch, unwatch } from '../components/LocalStorage/watcher';
import i18n from '../util/i18n';

let getUserPreferencesResolver;
const getUserPreferencesPromise = new Promise((resolve) => {
  getUserPreferencesResolver = resolve;
});
export const getUserPreferences = () => getUserPreferencesPromise;


let getObservableUserPreferencesResolver;
const getObservableUserPreferencesPromise = new Promise((resolve) => {
  getObservableUserPreferencesResolver = resolve;
});
export const getObservableUserPreferences = () => getObservableUserPreferencesPromise;

const queryAndSubscribe = () => {
  const graphClient = getClient();

  getObservableUserPreferencesResolver(graphClient.subscribe({
    query: subscribeUserPreferences,
  }));

  graphClient
    .query({ query: getUserPreferencesQuery, fetchPolicy: 'no-cache' })
    .then(result => getUserPreferencesResolver(result.data.userPreferences));
};

const onUserLoggedIn = () => {
  const session = getSavedSession();
  if (session && session.userId) {
    queryAndSubscribe();
    unwatch('pageproof.app.userData', onUserLoggedIn);
  }
};

const initialSession = getSavedSession();
if (initialSession && initialSession.userId) {
  queryAndSubscribe();
} else {
  watch('pageproof.app.userData', onUserLoggedIn);
}

// Handle appLanguage here as i18n does not want to import this service.
const appLanguageObserver = {
  next({ data: { userPreferences } }) {
    const { value } = userPreferences.appLanguage;
    if (value) {
      i18n.setLocale(value);
    }
  },
};

getObservableUserPreferences().then(observableUserPreferences => observableUserPreferences.subscribe(appLanguageObserver));
getUserPreferences().then(({ appLanguage: { value } }) => value && i18n.setLocale(value));
