/* Copyright (C) 2021 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from '../Text';
import getPasswordValidation from './getPasswordValidation';
import InlineTooltip from '../InlineTooltip';

const PasswordIndicatorText = ({ password }) => {
  const { lowercase, uppercase, numbers, length } = getPasswordValidation(password);
  return (
    <InlineTooltip>
      <Translation value="create-account.password-requirements.heading" />
      {!lowercase && <div><Translation value="create-account.password-requirements.lowercase" /></div>}
      {!uppercase && <div><Translation value="create-account.password-requirements.uppercase" /></div>}
      {!numbers && <div><Translation value="create-account.password-requirements.number" /></div>}
      {!length && <div><Translation value="create-account.password-requirements.length" /></div>}
    </InlineTooltip>
  );
};

if (process.env.NODE_ENV !== 'production') {
  PasswordIndicatorText.propTypes = {
    password: PropTypes.string.isRequired,
  };
}

export default PasswordIndicatorText;
