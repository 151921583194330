/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import { useEffect } from 'react';
import { addExternalFile, addEmail, addWebURL } from '../../components/ProofSetup/utils/addFile';
import requestProofSetup from '../../components/ProofSetup/utils/requestProofSetup';
import { sdk } from '../../util/sdk';

// TODO:  The component should be able to handle multiple proofs, but for now we will only handle one proof
export default function ProofSetupByExternalId({ initialRouteParams }) {
  useEffect(() => {
    const {
      source,
      id,
    } = initialRouteParams;

    if (!id || !source) {
      return;
    }

    sdk.proofs._getProofSetup(source, id)
      .then(({ payload }) => {
        const {
          proofs,
          collectionId,
          messageToReviewers,
        } = payload;


        const {
          name,
          type,
          dueDate,
          file,
          integrationReferences,
        } = proofs[0];

        const defaults = {
          name,
          messageToReviewers,
        };

        const shared = {
          addToCollection: collectionId
            ? { id: collectionId }
            : null,
        };

        if (dueDate) {
          defaults.dueDate = new Date(dueDate);
        }

        if (integrationReferences.length) {
          defaults.integrationReferences = integrationReferences;
        }


        requestProofSetup({
          parameters: {
            defaults,
            file,
          },
          shared,
        }, () => {
          if (type === 'static' && file) {
            addExternalFile(undefined, file.downloadUrl, file.name, undefined, undefined, true);
          }

          if (type === 'email') {
            addEmail();
          }

          if (type === 'web') {
            addWebURL(file.sourceUrl);
          }

          const { $location } = window.__pageproof_bridge__;
          $location.url('/create');
        });
      });
  }, []);

  return null;
}
