/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
import React, {Component} from 'react';
import classname from 'classname';
import PropTypes from 'prop-types';
import {Translation} from '../../components/Text';
import {Button} from '../../components/Button';
import styles from './paginationStyles.scss';
import {PROOFS_PER_PAGE} from '../../util/constants';

class PaginationContainer extends Component {
  state = {
    currentPage: 1,
    toBeCurrentPage: 1,
    previousClicked: false,
    nextClicked: false,
  };

  // eslint-disable-next-line camelcase, react/sort-comp
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isLoading !== nextProps.isLoading && !nextProps.isLoading) {
      this.updateClickHandling();
    }
    if (nextProps.resetPagination !== this.props.resetPagination) {
      this.setState({
        currentPage: 1,
        toBeCurrentPage: 1,
      });
    }
  }

  previous = () => {
    const currentPage = this.state.currentPage;
    this.setState({
      previousClicked: true,
    });
    if (currentPage > 1) {
      const page = currentPage - 1;
      this.goToPage(page);
    }
  };

  next = () => {
    this.setState({
      nextClicked: true,
    });
    if (this.isNextPageExist) {
      const page = this.state.currentPage + 1;
      this.goToPage(page);
    }
  };

  goToPage = (page) => {
    this.setState({
      toBeCurrentPage: page,
    });
    this.props.onPageChange(page);
  }

  isNextPageExist = () => {
    const currentPage = this.state.currentPage;
    const totalProofs = this.props.total;

    const remainingProofs = totalProofs - (currentPage * PROOFS_PER_PAGE);
    return remainingProofs > 0;
  }

  updateClickHandling() {
    this.setState({
      previousClicked: false,
      nextClicked: false,
      currentPage: this.state.toBeCurrentPage,
    });
  }

  render() {
    const {
      total,
      isLoading,
      count,
    } = this.props;

    const {
      currentPage,
      previousClicked,
      nextClicked,
    } = this.state;

    const totalPages = Math.ceil(total / PROOFS_PER_PAGE);

    return (
      <div className={styles.Pagination}>
        <br />
        <br />
        {(!isLoading || total > 0) &&
          <p>
            {<Translation
              value="pagination.showing-results"
              params={{page: currentPage, totalPages, count}}
            />}
          </p>
        }
        <br />
        <div className={classname(styles.Pagination__buttonGroup, ' app__btn-group app__btn-group--center')}>
          {(currentPage > 1) && <Button
            disabled={isLoading && previousClicked}
            variant="outline"
            label={<Translation value={previousClicked ? 'pagination.btn.previous.active' : 'pagination.btn.previous'} />}
            onClick={this.previous}
          />}
          {this.isNextPageExist() && <Button
            disabled={isLoading && nextClicked}
            variant="primary"
            label={
              <Translation value={nextClicked ? 'pagination.btn.next.active' : 'pagination.btn.next'} />}
            onClick={this.next}
          />}
        </div>
      </div>
    );
  }
}

PaginationContainer.defaultProps = {
  isLoading: false,
};


if (process.env.NODE_ENV !== 'production') {
  PaginationContainer.propTypes = {
    count: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    resetPagination: PropTypes.string,
  };
}

export default PaginationContainer;
